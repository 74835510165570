@import 'mixin';
@import 'variable';
@import 'common';
.greenBtnHeader {
  .greenBtn {
    min-height: 42px;
    border-radius: 26px;
    text-transform: capitalize;
    @include commonStyling($granny-apple-green, $nero, 0.875rem, 700);
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $green-teal;
      color: $white;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
}
.whiteBtnHeader {
  .whiteBtn {
    min-height: 42px;
    border-radius: 8px;
    text-transform: capitalize;
    top: 15px;
    color: $green-teal;

    @include commonStyling($white, $green-teal, 0.875rem, 700);
    span {
      color: #09b466;
      font-size: 1.125rem;
      font-weight: 600;
      padding: 0px 30px;
    }
    &:hover {
      background-color: #ffffff;
    }
    // .btnIcon {
    //   margin-left: 12px;
    // }
  }
}

.darkgreenBtnHeader {
  .darkgreenBtn {
    min-height: 42px;
    width: 230px;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    // width: 100%;
    text-align: center;
    text-transform: capitalize;
    @include commonStyling($green-teal, $white, 0.875rem, 700);
    > span {
      display: inline-block;
    }
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $pigment-green;
    }
    .btnIcon {
      margin-left: 12px;
      position: relative;
      top: 5px;
    }
  }
  .darkgreenBtnInfo {
    min-height: 42px;
    width: 150px;
    margin-left: 80px;
    margin-top: 15px;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    // width: 100%;
    text-align: center;
    text-transform: capitalize;
    @include commonStyling($green-teal, $white, 0.875rem, 700);
    > span {
      display: inline-block;
    }
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $pigment-green;
    }
  }

  .sub-btn {
    min-height: 42px;
    width: 110px;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    // width: 100%;
    text-align: center;
    text-transform: capitalize;
    @include commonStyling($green-teal, $white, 0.875rem, 700);
    > span {
      display: inline-block;
    }
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $pigment-green;
    }
    .btnIcon {
      margin-left: 12px;
      position: relative;
      top: 5px;
    }
  }
}
.darkgreenBtnTable {
  .darkgreenTable {
    //min-height: 42px;
    margin: 0px 10px;
    padding: 6px 13px;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    // width: 100%;
    text-align: center;
    text-transform: capitalize;
    @include commonStyling($green-teal, $white, 0.875rem, 700);
    > span {
      display: inline-block;
    }
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $pigment-green;
    }
    .btnIcon {
      margin-left: 12px;
      position: relative;
      top: 5px;
    }
  }
}

.blueBtnHeader {
  list-style: none;
  float: left;
  display: block;
  text-align: center;
  width: 160px;
  margin-top: 20px;
  .blueBtn {
    min-height: 42px;
    border-radius: 4px;
    text-transform: capitalize;
    @include commonStyling($dodger-blue, $white, 0.875rem, 700);
    height: 42px;
    border-radius: 13px;
    background-color: #1f4e9c;
    width: 160px;
    float: left;
    margin-left: 24px;
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $egyptian-blue;
      color: $white;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
}

.redBtnHeader {
  list-style: none;
  float: left;
  display: block;
  text-align: center;
  width: 160px;
  margin-top: 20px;
  .redBtn {
    min-height: 42px;
    color: $white;
    border-radius: 4px;
    text-transform: capitalize;
    span {
      color: $white;
    }
    @include commonStyling($radical-red, $dodger-blue, 0.875rem, 700);
    height: 42px;
    border-radius: 13px;
    background-color: #1574e3;
    width: 160px;
    float: left;
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: #1574e3;
      color: $white;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
}

.whatsappgreenBtnHeader {
  .whatsappgreenBtn {
    min-height: 42px;
    border-radius: 4px;
    text-transform: capitalize;
    @include commonStyling($kelley-green, $white, 0.875rem, 700);
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $pigment-green;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
}

.gmailwhiteBtnHeader {
  .gmailwhiteBtn {
    min-height: 42px;
    border-radius: 4px;
    text-transform: capitalize;
    @include commonStyling($white, $white, 0.875rem, 700);
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $white-smoke;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
}

.greyBtnHeader {
  .greyBtn {
    min-height: 42px;
    border-radius: 4px;
    text-transform: capitalize;
    @include commonStyling(rgba(204, 204, 204, 0.36), $dim-grey, 0.875rem, 700);
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $dark-grey;
      color: $white;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
  .back-btn {
    min-height: 42px;
    width: 110px;
    border-radius: 4px;
    text-transform: capitalize;
    @include commonStyling(rgba(204, 204, 204, 0.36), $dim-grey, 0.875rem, 700);
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $dark-grey;
      color: $white;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
}

.siFoloBtnHeader {
  .siFoloBtn {
    border-radius: 25px;
    min-height: 42px;
    text-transform: capitalize;
    @include commonStyling(#09b466, $white, 0.9375rem, 700);
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $kelley-green;
      color: $white;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
}

/*Gmail Button Start*/
.gmai-Btn {
  width: 160px;
  min-height: 42px;
  border: 1px solid #1574e3;
  border-radius: 13px;
  background-color: #1574e3;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: $white;
  img {
    float: left;
  }
}
/*Gmail Button End*/

/*FaceBook Button Start*/
.facebook-Btn {
  border-radius: 13px;
  background-color: #1f4e9c;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-left: 20px;
  color: $white;
  width: 160px;
  min-height: 42px;
  border: 1px solid #1f4e9c;
  cursor: pointer;
  img {
    float: left;
  }
}
/*FaceBook Button End*/
.greenBtnBorderHeader {
  .greenBorderBtn {
    min-height: 42px;
    text-transform: capitalize;
    border-radius: 4px;
    border: solid 1px $green-teal;
    color: $green-teal;
    width: 184px;
    &:hover {
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
        0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
      background-color: $green-teal;
      color: $white;
    }
    .btnIcon {
      margin-left: 12px;
    }
  }
}

.btn {
  .MuiButton-contained.Mui-disabled {
    color: white;
    box-shadow: none;
    background-color: #bbbbbb;
  }
  .btn-success {
    width: max-content;
    border-radius: 4px;
    width: max-content;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    text-align: center;
    text-transform: capitalize;
    background-color: $green-teal;
    color: $white;
    &:hover {
      background-color: $green-teal;
    }
    .btnIcon {
      margin-left: 12px;
      position: relative;
      top: 5px;
    }
  }
  .btn-outline-success {
    border-radius: 4px;
    border: 1px solid $green-teal;
    color: $green-teal;
    box-shadow: none;
    text-transform: capitalize;
    background-color: transparent;
    font-size: 12px;
    &:hover {
      background-color: $green-teal;
      color: $white;
    }
  }
  .btn-disable {
    border-radius: 4px;
    box-shadow: none;
    text-transform: capitalize;
    background-color: #bbbbbb;
    border: 1px solid #bbbbbb;
    color: #ffffff;
    font-weight: 700;
    &:hover {
      background-color: #bbbbbb;
      color: $white;
    }
  }
  .btn-outline-enable {
    border-radius: 4px;
    border: 1px solid $green-teal;
    color: $green-teal;
    box-shadow: none;
    text-transform: capitalize;
    background-color: transparent;
    font-size: 12px;
    &:hover {
      background-color: $green-teal;
      color: $white;
    }
  }
}
