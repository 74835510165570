.upload-container {
  background-color: #f5f5f5;
  border-radius: 4px;
  width: auto;
  padding: 30px;
  height: auto;
  h5 {
    padding-bottom: 10px;
    color: #09b466;
  }
  .dropzone {
    background-color: green;
    outline: 1px dashed #4da1ff;
    background-color: #fff;
    border-radius: 4px;
    width: 100%;
    height: auto;
    input {
      padding: 120px 0px 30px 35%;
      text-align: center !important;
      margin: 0;
      box-sizing: border-box;
      &:focus {
        outline: none;
      }
    }
    &:after {
      pointer-events: none;
      position: absolute;
      top: 95px;
      left: 0;
      width: 50px;
      right: 0;
      height: 56px;
      content: '';
      background-image: url('../../images/upload-icon.svg');
      display: block;
      margin: 0 auto;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &:before {
      position: absolute;
      bottom: 10px;
      top: 160px;
      left: 0;
      pointer-events: none;
      width: 100%;
      right: 0;
      height: 57px;
      content: ' You can also drop your files here ';
      display: block;
      margin: 0 auto;
      color: #4da1ff;
      font-weight: 600;
      text-transform: capitalize;
      text-align: center;
    }
  }
}
.uploaded-message {
  text-align: center;
  border-radius: 6px;
  border: 1px dashed #4da1ff;
  padding: 20px;
  background-color: #ffffff;
  p {
    margin-top: 10px;
  }
  h6 {
    span {
      color: #4da1ff;
    }
    i {
      color: #09b466;
    }
  }
}
.upload-message-left {
  text-align: center;
  p span {
    color: #09b466;
  }
}
.upload-message-right {
  p span {
    float: right;
    color: #a6123c;
    margin-right: 20px;
  }
}
.select-file {
  margin: auto 0;
}

.modal-footer .upload-btn {
  border: 1px solid #09b466;
  color: #009245;
  margin-top: 5px;
  width: 150px;
  &:hover {
    background-color: #09b466;
    color: #fff;
  }
}
.modal-footer .upload-btn-default {
  color: #fff;
  width: 150px;
  min-height: 28px;
  border-radius: 4px;
  background-color: #bbbbbb;
  margin-right: 12px;
}
.info-panel-slide {
  margin-top: 50px;
  position: absolute;
  width: 300px;
  z-index: 1000;
  height: auto;
  right: 40px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  .m-b-0 {
    margin-bottom: 0px;
  }
  .info-panel-btn {
    border: 1px solid #09b466;
    background-color: #009245;
    color: #fff;
    margin-top: 5px;
    width: 150px;
  }
}
.filter-panel {
  .filter-btn {
    border: 1px solid #09b466;
    background-color: #009245;
    color: #fff;
    margin-top: 5px;
    width: 80px;
    padding: 5px;
    i {
      font-size: 15px;
    }
    &:focus {
      outline: none;
    }
  }
}
.add-farmers-modal {
  height: 800px;
}
