/*=============
@media variable
=============*/
/*	@meida (max-width:320px) {};	*/
/*	@meida (max-width:767px) {};	*/
/*	@meida (max-width:991px) {};	*/
/*	@meida (min-width:1200px)	{};	*/
/*=============
Theme Layout
=============*/
/*	Left Menu should be overlay on body, set value 70px and for offset menu set 315px */
/*	Left Menu Close width is 70px */
/*	Layout full width - default value is 180px,
																						for remove right space $layout-fluid variable value should be  30px */
/* 	Workarea body with left and right space - default value is 50px
																						you can set left and right space by value like 0px or 10px */
/*====== Colors=========*/
/*====== White Colors=========*/
/*====== Black/Grey Colors=========*/
/*====== Green Colors=========*/
/*====== Yellow Colors=========*/
/*====== Orange Colors=========*/
/*====== Blue Colors=========*/
/*====== Red Colors=========*/
/*=============
	Media Queries
===============*/
.promotionalPanel {
  height: 462px;
  overflow-y: auto; }
  .promotionalPanel h4 {
    font-size: 1.125rem;
    font-weight: 500;
    color: #282828;
    margin-bottom: 12px; }
  .promotionalPanel .slide-section {
    border-radius: 4px;
    background-color: #eaebed;
    padding: 10px;
    margin-bottom: 24px; }
    .promotionalPanel .slide-section img {
      width: 160px;
      height: auto; }
    .promotionalPanel .slide-section .playbutton {
      background: url(/images/play-icon.svg) center center no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 74px;
      height: 74px;
      margin: -37px 0 0 -37px;
      z-index: 10; }
    .promotionalPanel .slide-section p {
      font-size: 15px;
      text-align: center;
      padding-top: 5px; }
    .promotionalPanel .slide-section .slide {
      background-color: #fff;
      height: 250px;
      margin: 10px; }
      .promotionalPanel .slide-section .slide .css-doq0dk {
        height: 160px; }
      .promotionalPanel .slide-section .slide__news {
        font-size: 12px;
        color: #4a4a4a;
        padding: 0px 10px 0px 10px; }
      .promotionalPanel .slide-section .slide__date {
        font-size: 10px;
        color: #a7aeb6;
        padding-left: 10px; }
      .promotionalPanel .slide-section .slide .slide-detail {
        display: flex; }
        .promotionalPanel .slide-section .slide .slide-detail .slide-left p,
        .promotionalPanel .slide-section .slide .slide-detail .slide-right p {
          font-size: 14px;
          font-weight: 600;
          color: #4a4a4a; }
        .promotionalPanel .slide-section .slide .slide-detail .slide-left span,
        .promotionalPanel .slide-section .slide .slide-detail .slide-right span {
          font-size: 12px;
          color: #09b466; }
  .promotionalPanel ul {
    border-radius: 4px;
    background-color: #eaebed;
    padding: 20px 15px;
    margin-bottom: 24px; }
    .promotionalPanel ul li {
      display: block;
      margin-bottom: 20px; }
      .promotionalPanel ul li span {
        display: inline-block;
        width: calc(100% - 40px);
        border-radius: 4px;
        background-color: #ffffff;
        text-decoration: none;
        padding: 20px;
        text-align: center; }
        .promotionalPanel ul li span img {
          display: block;
          width: 76px;
          height: 76px;
          border-radius: 4px;
          border: solid 1px #1bcb7b;
          margin-bottom: 12px; }
        .promotionalPanel ul li span h5 {
          font-size: 1.125rem;
          font-weight: 500;
          color: #4a4a4a;
          margin-bottom: 8px; }
        .promotionalPanel ul li span h6 .left {
          float: left;
          display: inline-block;
          width: auto;
          text-align: left;
          font-size: 12px;
          color: #09b466; }
        .promotionalPanel ul li span h6 .right {
          float: right;
          display: inline-block;
          width: auto;
          text-align: right;
          font-size: 12px;
          margin-top: 0px;
          color: #09b466; }
        .promotionalPanel ul li span p {
          font-size: 1rem;
          margin-bottom: 0px; }
          .promotionalPanel ul li span p span.left {
            font-size: 14px;
            color: #4a4a4a; }
          .promotionalPanel ul li span p span.right {
            font-size: 14px;
            color: #4a4a4a; }
          .promotionalPanel ul li span p span.info {
            font-size: 12px;
            color: #4a4a4a;
            float: left; }
          .promotionalPanel ul li span p span.date {
            font-size: 10px;
            color: #a7aeb6;
            float: left; }
      .promotionalPanel ul li:nth-last-child(1) {
        margin-bottom: 0px; }
