@import 'base/normalize';
@import 'mixin';
@import 'variable';
* {
  margin: 0 auto;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
body {
  overflow: hidden;
}
:root {
  font-size: 14px;
}
html {
  width: 100% !important;
}
.commonPadding {
  @include paddingController(0px, 15px, 0px, 15px);
}
.commonBorder {
  @include border(bottom, 2px, solid, $black);
}
.blackBorder {
  @include border(bottom, 1px, solid, #979797);
}
.greyBorder {
  @include border(bottom, 2px, solid, #e5e5e5);
  width: 50%;
  float: left;
  margin-top: 8px;
}
.imgResponsive {
  width: 100%;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.fullHeight {
  height: 98.5vh;
}
.textCenter {
  text-align: center;
}
.styles_modal__gNwvD {
  padding: 0;
  border-radius: 8px;
  max-width: 1000px;
}
.textAlignRight {
  text-align: right;
}
.varifyBtn {
  position: relative;
  top: -40px;
  text-align: right;
  left: 60%;
  padding: 1px;
  color: blue;
  cursor: pointer;
  font-size: 14px;
  border: none;
  background-color: transparent;
}
.MuiDrawer-modal .MuiBackdrop-root {
  width: 0px;
}
.MuiDrawer-paperAnchorRight {
  left: auto;
  right: 0;
  //width: 550px;
}
//.agentDetail{
//  .swapDrawer {
//    .swapDrawer{
//      .MuiDrawer-modal .MuiBackdrop-root{
//        width: 0px;
//      }
//    }
//  }
//}

//.tablegreenDot td:nth-last-child(odd)
//{
//  color: red;
//  background-color: transparent;
//  font-size: 0.875rem;
//}
.errorMsg {
  color: #ff0000;
  margin-top: 5px;
  font-weight: 700;
  font-size: 0.75rem;
  float: left;
  width: 100%;
  text-align: right;
}
.errorMsg1 {
  color: #ff0000;
  margin-top: 5px;
  font-weight: 700;
  font-size: 0.75rem;
  float: left;
  width: 100%;
  text-align: center;
}
@media (max-width: 600px) {
  body {
    overflow: scroll;
  }
}
