.modalHead {
  .paper {
    border: 2px solid #09b466;
    .modal-header {
      padding: 15px;
      border-bottom: 1px solid #e5e5e5;
      .modal-title {
        margin: 0;
        line-height: 1.43;
        h4 {
          color: #09b466;
        }
      }
      .mobileCloseIcon {
        margintop: -2px;
        float: right;
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #09b466;
        opacity: 1;
        .customClose {
          position: relative;
          top: -5px;
        }
      }
    }
    .modalSec {
      //border-top-left-radius: 12px;
      //border-top-right-radius: 12px;
      //background-color: #ffffff;
      //padding-top: 30px;
      //padding-left: 30px;
      //width: 279px;
      //padding-bottom: 30px;
      //padding-right: 30px;
      width: 300px;
      .modalContent {
        .inner {
          .separateFormLeft {
            padding-right: 20px;
            h4 {
              //font-weight: 400;
              //font-size: 0.875rem;
              //color: #465464;
              margin-bottom: 10px;
              //float: left;
              //width: 100%;
              //display: inline - block;
              //maxwidth: 100%;
            }
            .customForm {
              float: left;
              width: 100%;
              font-size: 12px;
              margin-bottom: 40px;
              .tableDropdown {
                height: 34px;
                width: 100%;
                padding: 6px 12px;
                display: inline - block;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      width: 100%;
      padding: 10px 0px;
      text-align: center;
      display: inline-block;
      border-top: 1px solid #e5e5e5;
      .customClearBtn {
        background-color: #09b466;
        color: #fff;
        //  fontSize: .875rem;
        padding: 5px 8px;
        border-radius: 4px;
        display: inline-block;
        margin-right: 18px;
        //textAlign: center;
        //fontWeight: 500;
        //minWidth: 85px;
      }
      .customAddBtn {
        background-color: lightgray;
        color: #fff;
        // fontSize: .875rem;
        padding: 5px 8px;
        border: 1px solid lightgray;
        border-radius: 4px;
        display: inline-block;
        //textAlign: center;
        //fontWeight: 500;
        //minWidth: 85px;
      }
    }
  }
}
