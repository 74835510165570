/*=============
@media variable
=============*/
/*	@meida (max-width:320px) {};	*/
/*	@meida (max-width:767px) {};	*/
/*	@meida (max-width:991px) {};	*/
/*	@meida (min-width:1200px)	{};	*/
/*=============
Theme Layout
=============*/
/*	Left Menu should be overlay on body, set value 70px and for offset menu set 315px */
/*	Left Menu Close width is 70px */
/*	Layout full width - default value is 180px,
																						for remove right space $layout-fluid variable value should be  30px */
/* 	Workarea body with left and right space - default value is 50px
																						you can set left and right space by value like 0px or 10px */
/*====== Colors=========*/
/*====== White Colors=========*/
/*====== Black/Grey Colors=========*/
/*====== Green Colors=========*/
/*====== Yellow Colors=========*/
/*====== Orange Colors=========*/
/*====== Blue Colors=========*/
/*====== Red Colors=========*/
/*=============
	Media Queries
===============*/
.insightPanel {
  overflow-y: auto;
  height: calc(100vh - 71px);
  padding: 20px 0px 20px 0px; }

.insightLeftPanel h5 {
  font-size: 1.5rem;
  color: #282828;
  margin-top: 0px;
  margin-bottom: 25px; }
  .insightLeftPanel h5 a {
    margin-left: 12px;
    font-size: 1rem;
    font-weight: 600;
    color: #09b466; }

.insightLeftPanel .recommendedPanel {
  float: left;
  width: 100%;
  margin-bottom: 35px; }
  .insightLeftPanel .recommendedPanel h6 {
    float: left;
    width: 145px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #09b466;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    padding: 10px;
    text-align: center; }
  .insightLeftPanel .recommendedPanel ul {
    float: left;
    width: 100%; }
    .insightLeftPanel .recommendedPanel ul > li {
      display: flex;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: solid 2px #09b466;
      background-color: #ffffff;
      box-sizing: border-box; }
      .insightLeftPanel .recommendedPanel ul > li i {
        display: inline-block;
        width: 48px;
        height: 48px;
        border-radius: 4px;
        background-color: #09b466;
        font-size: 1.125rem;
        font-weight: 900;
        color: #ffffff;
        text-align: center;
        padding-top: 13px;
        box-sizing: border-box;
        font-style: normal; }
      .insightLeftPanel .recommendedPanel ul > li .tagInfo {
        margin-left: 30px; }
        @media (max-width: 600px) {
          .insightLeftPanel .recommendedPanel ul > li .tagInfo {
            width: 100%; } }
        .insightLeftPanel .recommendedPanel ul > li .tagInfo p {
          font-size: 1.125rem;
          font-weight: 500;
          color: #4a4a4a; }
        .insightLeftPanel .recommendedPanel ul > li .tagInfo ol > li {
          display: inline-block;
          margin-right: 20px;
          font-size: 1rem;
          color: #09b466; }
          .insightLeftPanel .recommendedPanel ul > li .tagInfo ol > li.highRisk {
            color: #eb4a74;
            font-weight: 500; }
          .insightLeftPanel .recommendedPanel ul > li .tagInfo ol > li.midRisk {
            color: #f9aa1a;
            font-weight: 500; }
  .insightLeftPanel .recommendedPanel.darkgreenBtnHeader .darkgreenBtn {
    width: 127px; }

.insightLeftPanel .resultPanel h6 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom: 18px; }

.insightLeftPanel .resultPanel ul {
  float: left;
  width: 100%; }
  .insightLeftPanel .resultPanel ul > li {
    display: flex;
    margin-bottom: 25px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px -1px rgba(159, 205, 248, 0.2), 0 1px 10px 0 rgba(159, 205, 248, 0.12), 0 4px 5px 0 rgba(159, 205, 248, 0.16);
    background-color: #ffffff; }
    .insightLeftPanel .resultPanel ul > li i {
      display: inline-block;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      background-color: #09b466;
      font-size: 1.125rem;
      font-weight: 900;
      color: #ffffff;
      text-align: center;
      padding-top: 13px;
      box-sizing: border-box;
      font-style: normal; }
    .insightLeftPanel .resultPanel ul > li .tagInfo {
      margin-left: 30px; }
      @media (max-width: 600px) {
        .insightLeftPanel .resultPanel ul > li .tagInfo {
          width: 100%; } }
      .insightLeftPanel .resultPanel ul > li .tagInfo p {
        font-size: 1.125rem;
        font-weight: 500;
        color: #4a4a4a; }
      .insightLeftPanel .resultPanel ul > li .tagInfo ol > li {
        display: inline-block;
        margin-right: 20px;
        font-size: 1rem;
        color: #09b466; }
        .insightLeftPanel .resultPanel ul > li .tagInfo ol > li.highRisk {
          color: #eb4a74;
          font-weight: 500; }
        .insightLeftPanel .resultPanel ul > li .tagInfo ol > li.midRisk {
          color: #f9aa1a;
          font-weight: 500; }

.insightLeftPanel .resultPanel .tagBtn {
  width: 127px; }
  .insightLeftPanel .resultPanel .tagBtn button {
    margin-bottom: 6px; }

.insightLeftPanel .resultPanel.darkgreenBtnHeader .darkgreenBtn {
  width: 127px; }

.backWithBreadCrumb {
  margin-bottom: 20px; }
  .backWithBreadCrumb .backBtn {
    font-size: 0.875rem;
    font-weight: 600;
    color: #2a292a;
    display: flex;
    float: left; }
    .backWithBreadCrumb .backBtn i {
      margin-right: 3px;
      font-size: 1rem; }
  .backWithBreadCrumb .MuiBreadcrumbs-root {
    display: inline-block;
    border-left: 1px solid rgba(204, 204, 204, 0.36);
    margin-left: 30px;
    padding-left: 30px; }
    .backWithBreadCrumb .MuiBreadcrumbs-root .MuiBreadcrumbs-li {
      margin: 0; }
      .backWithBreadCrumb .MuiBreadcrumbs-root .MuiBreadcrumbs-li a {
        font-size: 0.875rem;
        font-weight: 500;
        color: #9a9c9b; }

.farmerSelectionPanel {
  border-radius: 4px;
  border: solid 2px #09b466;
  background-color: #ffffff; }
  .farmerSelectionPanel .selectedPoster {
    border-bottom: 2px solid #09b466;
    display: flex;
    margin-bottom: 25px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px -1px rgba(159, 205, 248, 0.2), 0 1px 10px 0 rgba(159, 205, 248, 0.12), 0 4px 5px 0 rgba(159, 205, 248, 0.16);
    background-color: #ffffff; }
    .farmerSelectionPanel .selectedPoster i {
      display: inline-block;
      width: 48px;
      height: 48px;
      margin: 0;
      border-radius: 4px;
      background-color: #09b466;
      font-size: 1.125rem;
      font-weight: 900;
      color: #ffffff;
      text-align: center;
      padding-top: 13px;
      box-sizing: border-box;
      font-style: normal; }
    .farmerSelectionPanel .selectedPoster .tagInfo {
      margin-left: 30px; }
      @media (max-width: 600px) {
        .farmerSelectionPanel .selectedPoster .tagInfo {
          width: 100%; } }
      .farmerSelectionPanel .selectedPoster .tagInfo p {
        font-size: 1.125rem;
        font-weight: 500;
        color: #4a4a4a; }
        .farmerSelectionPanel .selectedPoster .tagInfo p span {
          display: block;
          font-size: 0.875rem;
          color: #878888;
          margin-top: 6px; }
  .farmerSelectionPanel .addFarmerWithOutput {
    padding: 30px 24px 30px 24px; }
    .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers {
      padding-right: 20px; }
      .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers h4 {
        font-size: 1.25rem;
        font-weight: 500;
        color: #4a4a4a;
        margin-bottom: 18px; }
      .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li {
        border-radius: 4px;
        border: solid 1px #009245;
        background-color: #ffffff;
        padding: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        position: relative; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li i.tickIcon {
          color: #9d9d9d;
          font-size: 2rem; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li i.active {
          color: #009245; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .farmerDetail {
          width: calc(100% - 15px);
          margin-left: 15px;
          line-height: 15px; }
          .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .farmerDetail h6 {
            font-size: 1.25rem;
            font-weight: 500;
            color: #009245;
            margin-top: 0;
            margin-bottom: 6px; }
          .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .farmerDetail p {
            font-size: 1rem;
            font-weight: 500;
            color: #979797; }
            .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .farmerDetail p strong {
              color: #4a4a4a; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .viewFormatLink {
          font-size: 0.875rem;
          font-weight: 500;
          color: #9a9a9a;
          text-decoration: underline;
          margin-top: 5px;
          display: inline-block; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .uploadedFileName {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: calc(100% - 30px);
          border-radius: 6px;
          background-color: #f5f5f5;
          padding: 5px 15px;
          font-size: 0.875rem;
          font-weight: 500;
          color: #4a4a4a; }
          .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .uploadedFileName span {
            font-style: italic;
            color: #a9a9a9; }
          .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .uploadedFileName i {
            float: right;
            opacity: 0.58;
            color: #4a4a4a;
            font-size: 1rem;
            position: relative;
            top: 3px; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers ul > li .darkgreenBtn {
          min-height: 28px;
          border-radius: 4px;
          background-color: #09b466;
          width: 114px; }
      .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers li.uploadFileSec {
        padding-bottom: 35px; }
      .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiExpansionPanelSummary-root {
        border-radius: 6px;
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.22);
        background-color: #09b466;
        font-size: 1.25rem;
        font-weight: 500;
        color: #ffffff; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiExpansionPanelSummary-root .formControl {
          width: 150px; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiExpansionPanelSummary-root .MuiIconButton-label {
          color: #fff; }
      .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiCollapse-container {
        border-radius: 6px;
        border: 1px solid #979797;
        background-color: white;
        margin-top: 7px; }
      .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiCollapse-wrapper {
        display: block; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiCollapse-wrapper .MuiExpansionPanelDetails-root {
          display: block;
          padding: 15px; }
          .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiCollapse-wrapper .MuiExpansionPanelDetails-root .inputHead.coloredForms {
            display: inline-block;
            width: 32%;
            margin-right: 5px;
            margin-bottom: 10px; }
            .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiCollapse-wrapper .MuiExpansionPanelDetails-root .inputHead.coloredForms:after {
              top: 59%; }
            .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiCollapse-wrapper .MuiExpansionPanelDetails-root .inputHead.coloredForms label {
              font-size: 1rem; }
            .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .MuiCollapse-wrapper .MuiExpansionPanelDetails-root .inputHead.coloredForms .MuiSelect-select {
              height: 35px;
              border-radius: 4px;
              background-color: #ebedee;
              color: #696969;
              padding: 10px;
              box-sizing: border-box; }
      .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .farmerWithListBtn {
        display: flex; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .farmerWithListBtn h6 {
          font-size: 0.75rem;
          font-weight: 500;
          color: #282828;
          width: calc(100% - 127px);
          position: relative;
          top: 15px; }
          .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .farmerWithListBtn h6 span {
            color: #009245; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .farmerExpansionPanel .farmerWithListBtn .darkgreenBtn {
          width: 127px;
          min-height: 35px; }
      .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .rootTable {
        width: 100%; }
        .farmerSelectionPanel .addFarmerWithOutput .selectAddFarmers .rootTable .tableWrapper {
          max-height: 300px;
          overflow: auto; }
    .farmerSelectionPanel .addFarmerWithOutput .MuiExpansionPanelSummary-expandIcon {
      position: absolute;
      right: 30px;
      top: 0px;
      bottom: 0px; }
    .farmerSelectionPanel .addFarmerWithOutput .finalOutput {
      border-radius: 6px;
      border: solid 1px #979797;
      background-image: linear-gradient(233deg, #f9f9f9, #edeef3); }
      .farmerSelectionPanel .addFarmerWithOutput .finalOutput h4 {
        font-size: 1.25rem;
        font-weight: 500;
        color: #4a4a4a;
        padding-bottom: 6px;
        padding-bottom: 6px;
        margin-bottom: 20px; }
      .farmerSelectionPanel .addFarmerWithOutput .finalOutput .finalOutputImg {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 10px; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .finalOutputImg img {
          width: 150px; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .finalOutputImg .finalOutputDetail {
          padding: 10px; }
          .farmerSelectionPanel .addFarmerWithOutput .finalOutput .finalOutputImg .finalOutputDetail h5 {
            font-size: 1.25rem;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 7px; }
            .farmerSelectionPanel .addFarmerWithOutput .finalOutput .finalOutputImg .finalOutputDetail h5 a {
              font-size: 0.75rem;
              font-weight: 600;
              color: #009245; }
          .farmerSelectionPanel .addFarmerWithOutput .finalOutput .finalOutputImg .finalOutputDetail p {
            font-size: 0.75rem;
            font-weight: 500;
            color: #8e8e8e; }
            .farmerSelectionPanel .addFarmerWithOutput .finalOutput .finalOutputImg .finalOutputDetail p span {
              font-weight: 600;
              color: #727272; }
      .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleTime {
        margin-bottom: 10px;
        padding-bottom: 10px; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleTime h4 {
          margin-bottom: 0px;
          padding-bottom: 0px; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleTime .dateTimePicker .MuiFormControl-root {
          width: 35%;
          margin-right: 7px; }
          .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleTime .dateTimePicker .MuiFormControl-root label {
            font-size: 1.25rem;
            font-weight: 500;
            color: #282828; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleTime .dateTimeValue li {
          display: inline-block;
          font-size: 0.75rem;
          color: #282828;
          margin-right: 15px;
          margin-top: 22px; }
          .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleTime .dateTimeValue li span {
            font-weight: 500;
            color: #989898; }
      .farmerSelectionPanel .addFarmerWithOutput .finalOutput .farmerCount {
        margin-bottom: 10px;
        padding-bottom: 10px; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .farmerCount h6 {
          font-size: 1.25rem;
          font-weight: 500;
          color: #282828; }
          .farmerSelectionPanel .addFarmerWithOutput .finalOutput .farmerCount h6 span {
            color: #009245; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .farmerCount p {
          font-size: 0.875rem;
          font-weight: 500;
          color: #282828; }
          .farmerSelectionPanel .addFarmerWithOutput .finalOutput .farmerCount p span {
            color: #009245; }
      .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleSms .enterNumber {
        display: flex;
        margin-bottom: 18px; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleSms .enterNumber .darkgreenBtn {
          height: 40px;
          width: 180px;
          margin-left: 10px; }
      .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleSms .acknowledgeCheckbox {
        font-size: 0.75rem;
        font-weight: 500;
        color: #282828;
        margin-bottom: 18px; }
      .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleSms .scheduleSmsBtn {
        background: transparent;
        text-align: center; }
        .farmerSelectionPanel .addFarmerWithOutput .finalOutput .scheduleSms .scheduleSmsBtn button {
          width: 180px;
          height: 40px; }

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none; }

.greenTxt {
  color: #01a053; }

.nobileGreyTxt {
  color: #9b9b9b; }

h4,
h5,
h6 {
  font-weight: 500;
  margin: 0; }

h4 {
  font-size: 1.25rem; }

h5 {
  font-size: 1.125rem; }

h6 {
  font-size: 1rem; }

button {
  display: inline-block;
  border-radius: 4px;
  text-transform: capitalize; }
  button.pencilBtn {
    width: 114px;
    height: 28px;
    line-height: 1.5; }
  button.greenBg {
    background-color: #01a053;
    color: #ffffff; }
    button.greenBg:hover {
      background: #01a053;
      color: #ffffff; }
  button.fullWidth {
    width: 100%;
    display: block;
    text-transform: none;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: left;
    padding: 27px 25px;
    position: relative; }
    button.fullWidth i {
      position: absolute;
      right: 25px;
      top: 50%;
      margin-top: -3px;
      height: 12px;
      width: 12px;
      text-align: center;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px; }
      button.fullWidth i.up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg); }

.linkBtn {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer; }
  .linkBtn.grayTxt {
    color: #9b9b9b;
    text-decoration: underline; }
  .linkBtn.greenTxt {
    color: #01a053; }

.voffset3 {
  margin-top: 3px; }

.voffset5 {
  margin-top: 5px; }

.voffset10 {
  margin-top: 10px; }

.voffset15 {
  margin-top: 15px; }

.img-responsive {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: auto; }

.breadCrumb {
  display: block; }
  .breadCrumb li {
    display: inline-block;
    color: #9b9b9b;
    position: relative;
    padding-right: 15px;
    font-size: 0.8125rem;
    font-weight: 500; }
    .breadCrumb li:after {
      position: absolute;
      content: '>';
      right: 4px; }
    .breadCrumb li.backLink {
      /*border-right: 1px solid $nobile-grey;
      margin-right: 20px;*/ }
      .breadCrumb li.backLink a {
        color: #4a4a4a;
        padding-left: 20px; }
        .breadCrumb li.backLink a:before {
          position: absolute;
          left: 0;
          content: '\2190';
          font-size: 1.25rem;
          top: -10px; }
    .breadCrumb li a {
      color: #9b9b9b;
      position: relative; }
      .breadCrumb li a.active {
        color: #4a4a4a; }
    .breadCrumb li:last-child:after, .breadCrumb li:first-child:after {
      content: ''; }

.cardCount {
  display: block;
  min-width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: #01a053;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  overflow: hidden;
  line-height: 48px;
  font-size: 1.125rem;
  font-weight: 900; }

.cardHolder {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  border: 2px solid #01a053;
  padding: 28px;
  border-radius: 4px;
  background: #ffffff; }
  .cardHolder li {
    display: inline-block;
    padding-right: 20px; }
    .cardHolder li h5 {
      color: #4a4a4a; }
  .cardHolder .labelList span {
    display: inline-block;
    margin-top: 5px;
    margin-right: 20px; }
  .cardHolder .hintTxt {
    font-size: 0.875rem;
    color: #878786; }
  .cardHolder h5 {
    margin: 0;
    font-size: 1.125rem; }

.posterWrap {
  border: 2px solid #01a053;
  padding: 10px 28px 10px 28px;
  border-radius: 4px;
  background: #ffffff;
  color: #4a4a4a;
  /*Select Poster CSS Start*/
  /*Select Poster CSS End*/ }
  .posterWrap.cardDetails {
    position: relative;
    top: -3px;
    margin-bottom: 50px; }
  .posterWrap.card-panel {
    max-height: 435px;
    overflow-y: auto; }
  .posterWrap .disclaimer p {
    font-size: 13px;
    color: #000000; }
    .posterWrap .disclaimer p span {
      font-weight: bold;
      color: #09b466;
      font-size: 15px;
      margin-right: 2px; }
  .posterWrap .posterWrapImg {
    display: inline-block; }
  .posterWrap .boxes {
    display: table;
    width: 100%;
    height: 100%; }
    .posterWrap .boxes .box {
      display: table-cell;
      text-align: center;
      vertical-align: top; }
      .posterWrap .boxes .box ul {
        list-style: none;
        display: inline-block; }
        .posterWrap .boxes .box ul li {
          margin: 12px 30px;
          display: block; }
          .posterWrap .boxes .box ul li img {
            width: 74px;
            height: 90px;
            opacity: 0.54;
            background-color: #67cc7d; }
    .posterWrap .boxes .box1 {
      width: 20px; }
    .posterWrap .boxes .box2 {
      width: 300px;
      display: inline-block;
      margin: 10px; }
      .posterWrap .boxes .box2 h1 {
        font-size: 16px;
        font-weight: 500;
        color: #4a4a4a; }
      .posterWrap .boxes .box2 span {
        border-radius: 5px;
        border: solid 1px #979797;
        padding: 20px;
        background-color: #fff;
        cursor: pointer;
        position: relative;
        font-weight: 500;
        line-height: normal;
        color: #292a29;
        top: 5px;
        font-size: 1rem; }
    .posterWrap .boxes .panjikaranFooter {
      position: relative;
      top: 50px; }
      .posterWrap .boxes .panjikaranFooter ul {
        display: flex;
        align-items: center;
        justify-content: center; }
        .posterWrap .boxes .panjikaranFooter ul li {
          margin: 0px 10px; }
          .posterWrap .boxes .panjikaranFooter ul li .posterUpload {
            border-radius: 4px;
            border: solid 1.4px #636e78;
            padding: 10px 30px; }
            .posterWrap .boxes .panjikaranFooter ul li .posterUpload span {
              font-size: 0.75rem;
              font-weight: 500;
              color: #102a43; }
    .posterWrap .boxes .box3 {
      width: 100%; }
      .posterWrap .boxes .box3 h3 {
        text-align: left;
        margin: 50px 17px;
        font-size: 32px;
        font-weight: 500;
        color: #4a4a4a;
        font-size: 2rem; }
      .posterWrap .boxes .box3 p {
        font-weight: 500;
        font-size: 1rem;
        color: #8e8e8e;
        text-align: left;
        text-align: left;
        margin: 0px 15px; }
        .posterWrap .boxes .box3 p span {
          font-weight: 600;
          color: #727272;
          font-size: 1rem; }
      .posterWrap .boxes .box3 .recomondationText {
        display: inline-block;
        margin-top: 18px; }
        .posterWrap .boxes .box3 .recomondationText .btn {
          float: right; }
        .posterWrap .boxes .box3 .recomondationText p {
          color: #878787;
          font-size: 0.875rem;
          border-bottom: 2px solid #878787;
          padding-bottom: 10px; }
          .posterWrap .boxes .box3 .recomondationText p span {
            font-weight: 500;
            color: #57ac64;
            font-size: 0.875rem; }
      .posterWrap .boxes .box3 .capturePoint ul {
        width: 100%;
        display: flex; }
        .posterWrap .boxes .box3 .capturePoint ul li {
          margin: 12px 30px;
          display: block;
          /* float: left; */
          display: inline-block;
          list-style-type: none;
          margin: 20px 15px;
          text-align: left;
          color: #292a29;
          width: 100%; }
          .posterWrap .boxes .box3 .capturePoint ul li p {
            font-size: 0.875rem;
            font-weight: 500;
            color: #292a29;
            float: right; }
            .posterWrap .boxes .box3 .capturePoint ul li p span {
              color: #009245;
              font-size: 0.875rem;
              display: block;
              float: right;
              margin-top: 2px; }
      .posterWrap .boxes .box3 .capturePointName {
        display: inline-block;
        width: 96%;
        text-align: left;
        margin: 0 auto;
        border-bottom: 2px solid #878787;
        padding-bottom: 10px; }
        .posterWrap .boxes .box3 .capturePointName .checkBox {
          display: inline-block;
          width: 383px;
          margin-left: 16px; }
        .posterWrap .boxes .box3 .capturePointName span {
          color: #009245;
          font-size: 14px;
          font-weight: 500; }
      .posterWrap .boxes .box3 .inputHeadTextArea {
        min-width: 500px;
        position: relative;
        top: -12px;
        border-radius: 10px;
        color: #09b466;
        font-size: 1.125rem;
        border: 1px solid #09b466;
        background-color: #f9fef9;
        padding-top: 12px;
        margin-left: 20px;
        padding-left: 20px;
        padding-bottom: 12px;
        padding-right: 20px;
        box-sizing: border-box; }
        .posterWrap .boxes .box3 .inputHeadTextArea .textarea {
          width: 100%; }
          .posterWrap .boxes .box3 .inputHeadTextArea .textarea::placeholder {
            width: 100%; }
      .posterWrap .boxes .box3 .farmerBtn {
        display: inline-block;
        float: right;
        margin-right: 25px;
        align-items: right; }

.posterList {
  padding-left: 30px; }
  .posterList li {
    display: inline-block;
    margin-right: 20px;
    margin-top: 20px;
    width: 184px;
    height: 226px;
    cursor: pointer;
    overflow: hidden; }
    .posterList li img {
      height: 272px; }
    .posterList li .plItem {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden; }
      .posterList li .plItem img {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: auto;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 0.25s ease;
        z-index: 1; }
      .posterList li .plItem span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(142, 230, 190, 0); }
      .posterList li .plItem:hover img {
        -webkit-filter: grayscale(0);
        filter: grayscale(0); }
      .posterList li .plItem.selected img {
        -webkit-filter: grayscale(0);
        filter: grayscale(0); }
      .posterList li .plItem.selected span {
        background: rgba(142, 230, 190, 0.55); }
        .posterList li .plItem.selected span:after {
          position: absolute;
          width: 48px;
          height: 48px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: '';
          background-color: #ffffff;
          mask-position: center, top left;
          mask-repeat: no-repeat, no-repeat;
          -webkit-mask-image: url("../../images/icon-check-circle.png");
          mask-size: cover; }

.posterBtm {
  margin-top: 30px;
  margin-bottom: 15px;
  display: block;
  text-align: center; }
  .posterBtm li {
    display: inline-block;
    margin: 10px 5px; }
    .posterBtm li button {
      width: 180px;
      height: 40px;
      text-align: center;
      background-color: #01a053;
      text-transform: capitalize;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500; }
      .posterBtm li button:hover {
        background-color: #01a053; }
      .posterBtm li button.thinBtn {
        width: 180px;
        height: 36px; }

.tickMark {
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: solid 2px #9b9b9b;
  cursor: pointer;
  position: relative;
  top: 3px; }
  .tickMark:before {
    content: '';
    height: 18px;
    width: 18px;
    left: 50%;
    top: 50%;
    margin-left: -9px;
    margin-top: -9px;
    position: absolute;
    background-color: #9b9b9b;
    mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-image: url("../../images/icon-tick.png");
    mask-size: cover; }
  .tickMark.selected {
    background: #01a053;
    border: 0; }
    .tickMark.selected:before {
      background-color: #ffffff; }

.myFarmersWrap {
  margin-bottom: 15px;
  margin-top: 8px;
  border-radius: 6px;
  border: solid 2px #979797; }
  .myFarmersWrap.greenBorder {
    border-radius: 4px;
    border: solid 2px #01a053; }
  .myFarmersWrap.bottomCnt {
    padding: 0;
    padding-top: 10px; }
  .myFarmersWrap .cardbBtmInfo {
    display: block;
    padding: 5px 20px;
    background: #f5f5f5;
    font-size: 0.875rem;
    font-weight: 500;
    position: relative; }
    .myFarmersWrap .cardbBtmInfo span {
      color: #878786; }
      .myFarmersWrap .cardbBtmInfo span.fileNameVal {
        font-style: italic; }
    .myFarmersWrap .cardbBtmInfo .closeIcon {
      position: absolute;
      right: 0;
      font-style: normal;
      color: #979797;
      font-size: 0.75rem;
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 24px;
      cursor: pointer; }

.myFarmersItems {
  display: flex;
  flex-direction: row; }
  .myFarmersItems li {
    padding: 20px; }
    .myFarmersItems li:first-child {
      width: 50px; }
    .myFarmersItems li:last-child {
      text-align: center;
      margin-left: auto;
      padding-left: 0; }
    .myFarmersItems li .linkBtn {
      margin-top: 4px; }

.finalOutputCard {
  padding: 12px;
  border-radius: 6px;
  border: solid 2px #979797;
  background-image: linear-gradient(233deg, #f9fef9, #edeef3);
  color: #4a4a4a; }
  .finalOutputCard h5,
  .finalOutputCard h6 {
    margin: 0;
    font-weight: 500; }
  .finalOutputCard h5 {
    font-size: 1.25rem; }
  .finalOutputCard hr {
    margin: 3px 0;
    border: 0;
    border-bottom: 1px solid #9b9b9b;
    box-shadow: none; }
  .finalOutputCard section {
    padding-top: 10px;
    padding-bottom: 10px; }
  .finalOutputCard h6 {
    font-size: 0.75rem;
    color: #282828; }

.myFarmersDataShow {
  border-radius: 6px;
  border: solid 2px #979797;
  width: 100%;
  margin-top: 15px; }

.tblBorderGrid {
  border-collapse: collapse;
  border: 1px solid #f5f5f5;
  table-layout: fixed;
  width: 100%; }
  .tblBorderGrid td,
  .tblBorderGrid th {
    border: 1px solid #f5f5f5;
    padding: 5px 10px;
    word-wrap: break-word;
    text-align: left;
    font-size: 0.75rem; }
  .tblBorderGrid th {
    background: #f5f5f5;
    text-transform: capitalize; }

.mfdsBottom {
  text-align: right;
  width: 100%; }
  .mfdsBottom a {
    font-size: 0.75rem;
    text-decoration: none;
    display: inline-block;
    padding: 4px 20px; }
    .mfdsBottom a:hover {
      text-decoration: underline; }

.finalPosterInfo {
  padding-top: 15px;
  padding-bottom: 10px; }
  .finalPosterInfo .pinfoHead {
    position: relative; }
    .finalPosterInfo .pinfoHead a {
      position: absolute;
      right: 0;
      top: 7px;
      font-size: 0.75rem;
      font-weight: 600;
      text-decoration: underline; }
  .finalPosterInfo .pinfoCnt p {
    font-size: 0.75rem;
    font-weight: 500;
    color: #878786; }
    .finalPosterInfo .pinfoCnt p label {
      font-weight: 600; }

.formWrap .MuiInput-root {
  position: relative;
  width: 100%; }

.formWrap button {
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: #01a053;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px; }
  .formWrap button:hover {
    background-color: #01a053;
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 10px; }
    .formWrap button:hover:hover {
      background-color: #01a053;
      color: #ffffff; }
  .formWrap button input[type='tel'] {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    padding-left: 10px;
    background-color: #e3e4e5;
    display: inline-block;
    padding: 0;
    border: 0 !important;
    text-align: center; }
  .formWrap button .MuiFormControlLabel-label {
    font-size: 0.75rem; }

.formWrap .reportFiltersBars {
  display: block;
  text-align: right;
  height: 30px;
  position: relative;
  top: -5px; }
  .formWrap .reportFiltersBars li {
    display: inline-block;
    padding-left: 25px;
    height: 26px; }
    .formWrap .reportFiltersBars li a {
      font-size: 1rem;
      font-weight: 500;
      color: #9b9b9b; }

.formWrap .cstmSlct .MuiInputBase-root {
  background: #ffffff;
  height: 26px;
  border-radius: 4px;
  border: solid 1px #979797;
  padding-left: 15px;
  font-size: 0.75rem;
  font-weight: 500;
  color: #282828; }
  .formWrap .cstmSlct .MuiInputBase-root:before {
    border: 0; }
  .formWrap .cstmSlct .MuiInputBase-root:hover:before {
    border: 0; }

.formWrap .cstmSlct .MuiInput-underline:hover:not(.Mui-disabled):before {
  border: 0; }

.formWrap .searchIconWrap {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  display: inline-block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
  top: 8px;
  cursor: pointer; }
  .formWrap .searchIconWrap:before {
    display: inline-block;
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 6px;
    top: 6px;
    background-color: #09b466;
    mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-image: url("../../images/icon-search.png");
    mask-size: cover; }

.formWrap .icon-pencil {
  left: -5px;
  height: 11px;
  width: 11px;
  position: relative;
  display: inline-block;
  background-color: #000000;
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../images/icon-pencil.png");
  mask-size: cover; }

.formWrap .reportTblGrid {
  border-radius: 4px;
  background: #ffffff; }

.icon-pencil {
  left: -5px;
  height: 11px;
  width: 11px;
  position: relative;
  display: inline-block;
  background-color: #000000;
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../images/icon-pencil.png");
  mask-size: cover; }

.reportTblGrid {
  border-radius: 4px;
  background: #ffffff; }
  .reportTblGrid table {
    color: #4a4a4a; }
    .reportTblGrid table .actionCol {
      width: 100px; }
    .reportTblGrid table .actionddCol {
      width: 50px; }
    .reportTblGrid table thead {
      background: #d8d8d8;
      text-transform: capitalize; }
      .reportTblGrid table thead th {
        font-size: 0.813rem;
        padding: 10px 18px;
        font-weight: 600; }
    .reportTblGrid table td {
      font-weight: 600;
      font-size: 0.875rem;
      padding: 10px 18px; }
      .reportTblGrid table td .linkBtn {
        color: #4a4a4a;
        font-weight: 600;
        text-decoration: underline; }

.hcircles {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 20px;
  cursor: pointer;
  text-align: center; }
  .hcircles i {
    position: relative;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: #9b9b9b;
    display: inline-block; }
    .hcircles i:before, .hcircles i:after {
      position: absolute;
      content: '';
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #9b9b9b; }
    .hcircles i:before {
      left: -6px; }
    .hcircles i:after {
      right: -6px; }

@media screen and (max-width: 575px) {
  .myFarmersItems {
    flex-direction: column;
    width: 100%;
    flex: 1; } }

.upload-box {
  border: 1px solid #009245;
  width: auto;
  border-radius: 4px;
  height: auto;
  margin-bottom: 10px; }
  .upload-box i {
    color: #9d9d9d;
    font-size: 2rem; }
  .upload-box h6 {
    font-size: 1.25rem;
    font-weight: 500;
    color: #009245;
    margin-top: 0;
    margin-bottom: 0px; }
  .upload-box p {
    font-size: 1rem;
    font-weight: 500;
    color: #979797; }
    .upload-box p span {
      color: #4a4a4a;
      font-size: 16px; }
  .upload-box .upload-view a {
    font-size: 0.875rem;
    font-weight: 500;
    color: #9a9a9a;
    text-align: right;
    float: left;
    text-decoration: underline;
    margin-top: 20px;
    display: inline-block; }
  .upload-box .upload-enable {
    border: 1px solid #09b466;
    color: #009245;
    margin-top: 5px;
    width: 115px;
    margin-right: 22px;
    text-transform: capitalize; }
    .upload-box .upload-enable:hover {
      background-color: #09b466;
      color: #fff; }
  .upload-box .upload-btn {
    min-height: 28px;
    border-radius: 4px;
    background-color: #bbbbbb;
    color: #ffffff;
    width: 80px;
    float: right; }
  .upload-box .upload-btn-enable {
    height: 33px;
    border-radius: 4px;
    border: 1px solid #09b466;
    background-color: #009245;
    color: #fff;
    width: auto;
    float: right;
    text-transform: capitalize; }
    .upload-box .upload-btn-enable:hover {
      background-color: #09b466;
      color: #fff; }
  .upload-box .MuiButton-root.Mui-disabled {
    color: #ffffff;
    box-shadow: none;
    text-transform: capitalize; }
  .upload-box .btn-panel .MuiButton-root.Mui-disabled {
    color: #09b466;
    box-shadow: none;
    text-transform: capitalize; }
  .upload-box .upload-btn-disable {
    border: 1px solid #09b466;
    color: #09b466;
    margin-top: 5px;
    width: 115px; }

.upload-content {
  padding: 20px; }
  .upload-content i.tickIcon {
    color: #d0c7c7;
    font-size: 40px; }
  .upload-content i.active {
    color: #09b466;
    font-size: 40px; }

.upload-box-footer {
  background-color: #f5f5f5; }
  .upload-box-footer .footer-content {
    margin-left: 10px; }
    .upload-box-footer .footer-content span {
      font-style: italic;
      color: #a9a9a9;
      font-size: 12px; }
    .upload-box-footer .footer-content i {
      float: right;
      opacity: 0.58;
      color: #4a4a4a;
      font-size: 1rem;
      top: 3px; }
