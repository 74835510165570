@media (min-width: 768px) and (max-width: 1024px) {
  .taggingTabPanel {
    .tabPanelMain > .MuiTypography-body1 {
      .scrollableTagging {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  /*  .tabPanelMain>.MuiTypography-body1 {
     height: auto;
     position: relative;
  }
  .promotionalPanel {
     height: auto;
     overflow-y: auto;
  }*/
  .posterWrap {
    .posterSection {
      max-width: 245px;
      img {
        max-width: 245px;
      }
    }
  }
  .sms__create--textarea {
    width: 455px;
  }
  .sms__create .inputHead {
    width: 490px;
  }
  .leftAside {
    .basePanel {
      .member_list {
        .sub-heading {
          .pull-right {
            margin-right: 16px;
          }
        }
        .list-heading {
          h1 {
            span {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .upload-box .upload-enable {
    width: auto;
    font-size: 10px;
  }
  .upload-box {
    .btn-panel {
      display: flex;
      .MuiButton-root.Mui-disabled {
        width: auto;
        height: fit-content;
        font-size: 10px;
      }
    }
    .upload-view button {
      margin-left: 30px;
    }
  }
  .modal-dialog .addMobileNo {
    width: 25%;
  }
  .modal-dialog .modal-body {
    padding: 0px 22px 0px 22px;
  }
  .withBorder {
    .btn {
      display: flex;
      .btnAlign {
        font-size: 8px;
      }
    }
  }
  .iconbb {
    max-width: 150px;
    .posterSection {
      max-width: 150px;
      max-height: 220px;
      img {
        max-width: 150px;
        max-height: 220px;
      }
    }
  }
  .totalStats {
    max-width: 200px;
  }
  .posterWrap .uploadPoster .ml {
    float: right;
    margin-right: 10px;
  }
  .insightRightPanel {
    min-height: 675px;
  }
}
