/*===============
prefix Mixin
=================*/

@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
  }
}

@mixin commonStyling($bgClr, $clr, $fntSze, $fntWgt) {
  background-color: $bgClr;
  color: $clr;
  font-size: $fntSze;
  font-weight: $fntWgt;
}

@mixin paddingController($top, $left, $btm, $right) {
  padding-top: $top;
  padding-left: $left;
  padding-bottom: $btm;
  padding-right: $right;
}
@mixin border($postn, $width, $type, $clr) {
  border-#{$postn}: $type $width $clr;
}

@mixin borderAll($width, $style, $color) {
  border: $width $style $color;
}
