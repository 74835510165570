.custom-alignTextField {
  display: flex;
  text-align: center;
}
.mediaUpload {
  //display: block;
  //padding: 80px 0 80px 40px;
  //float: left;
  //width: calc(100% - 40px);
  //position: relative;
  padding: 20px 0px;
  /*  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;*/
  text-align: center;
  // background-color: #a7b1bb;
  //display: block;
  //padding: 40px 0 20px 20px;
  //float: left;
  //width: calc(100% - 40px);
  //position: relative;
  input {
    margin-left: 100px;
    color: #09b466;
    margin-bottom: 19px;
  }
  h1 {
    margin-bottom: 5px;
  }
  h5 {
    color: #979797;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  /* input[type='file'] {
    z-index: -1;
    position: absolute;
    opacity: 0;
  }*/
  label {
    background-color: #09b466;
    border-radius: 7px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    padding: 10px 25px 10px 25px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  /*  input:focus + label {
    outline: 2px solid;
  }*/
  > ul {
    padding: 18px 60px;
    border: none;
    background: #f8fafc;
    li {
      margin-right: 100px;
      text-align: center;
      a {
        padding: 0;
        font-size: 1rem;
        color: #465464;
        border: none;
        background: transparent;
        &:hover,
        &:focus {
          background: transparent;
          border: none;
          opacity: 1;
          outline: none;
        }
        svg.tabClose {
          color: #a8b7c7;
          float: right;
        }
      }
    }
    li.active {
      a {
        border: none;
        font-weight: 500;
        color: #465464;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          height: 3px;
          width: 100%;
          background: #3c84f7;
          bottom: -15px;
          left: 0px;
        }
        &:focus,
        &:hover {
          background: transparent;
          border: transparent;
        }
      }
    }
  }
  .uploadFile {
    position: relative;
    margin-left: 30%;
    .customUploadFile {
      width: 200px;
      height: 200px;
      padding: 10px;
      background-color: #f9fef9;
      border: 1px solid #09b466;
      border-radius: 100%;
      font-size: 1rem;
      font-weight: 500;
      color: #09b466;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      svg {
        font-size: 2.8125rem;
        //color: #a8b7c7;
        display: inline-block;
        color: #09b466;
        width: 100%;
        margin-bottom: 13px;
      }
      .customUpload {
        margin-bottom: 10px;
      }
      span {
        display: block;
        margin-top: 13px;
        color: #a8b7c7;
      }
    }
  }
  .tab-content {
    padding: 80px 0px 80px 40px;
    float: left;
    width: calc(100% - 40px);
    position: relative;
  }
}
.media-body {
  background-color: #f4f5f5;
  height: 383px;
  p.errorText {
    text-align: center;
    color: red;
    font-size: 12px;
  }
}
