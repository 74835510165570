.loaderHead {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100vh;
  width: 100%;
  display: flex;
  .loaderLeftPanel {
    float: left;
    width: 320px;
    img {
      width: 100%;
      height: 100vh;
    }
  }
  .loaderRightPanel {
    float: left;
    width: calc(100% - 320px);
    img {
      width: 100%;
      height: 100vh;
    }
  }
}
@media (max-width: 600px) {
  .loaderHead {
    flex-wrap: wrap;
    .loaderLeftPanel {
      width: 100%;
      height: 30vh;
      order: 2;
      img {
        height: auto;
      }
    }
    .loaderRightPanel {
      width: 100%;
      height: 70vh;
      order: 1;
    }
  }
}
