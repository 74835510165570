/*=============
@media variable
=============*/
/*	@meida (max-width:320px) {};	*/
/*	@meida (max-width:767px) {};	*/
/*	@meida (max-width:991px) {};	*/
/*	@meida (min-width:1200px)	{};	*/
/*=============
Theme Layout
=============*/
/*	Left Menu should be overlay on body, set value 70px and for offset menu set 315px */
/*	Left Menu Close width is 70px */
/*	Layout full width - default value is 180px,
																						for remove right space $layout-fluid variable value should be  30px */
/* 	Workarea body with left and right space - default value is 50px
																						you can set left and right space by value like 0px or 10px */
/*====== Colors=========*/
/*====== White Colors=========*/
/*====== Black/Grey Colors=========*/
/*====== Green Colors=========*/
/*====== Yellow Colors=========*/
/*====== Orange Colors=========*/
/*====== Blue Colors=========*/
/*====== Red Colors=========*/
/*=============
	Media Queries
===============*/
/*New Select Poster CSS Start*/
.posterWrap .posterWrapImg ul {
  display: inline-block; }
  .posterWrap .posterWrapImg ul li {
    margin: 10px; }
    .posterWrap .posterWrapImg ul li img {
      width: 84px;
      height: auto;
      opacity: 0.54; }

.posterWrap .posterSection {
  display: inline-block;
  margin-top: 30px;
  border: 2px solid #09b466;
  width: 290px;
  height: 330px; }
  .posterWrap .posterSection h1 {
    font-size: 1rem;
    font-weight: 500;
    color: #4a4a4a; }
  .posterWrap .posterSection img {
    width: 100%;
    display: inline-block;
    width: 290px;
    height: 330px;
    cursor: pointer;
    overflow: hidden; }
  .posterWrap .posterSection img[alt] {
    color: #09b466;
    font-style: italic;
    font-size: 14px; }
  .posterWrap .posterSection .dataPoints {
    border: 1px solid #979797; }
  .posterWrap .posterSection span {
    border: 1px solid #979797;
    background-color: #fff;
    padding: 15px 27px;
    display: block;
    position: relative;
    bottom: 18px;
    cursor: pointer; }

.posterWrap .uploadPoster {
  padding: 10px;
  display: inline-block; }
  .posterWrap .uploadPoster h2 {
    font-size: 14px;
    font-weight: 500;
    color: #292a29; }
  .posterWrap .uploadPoster input {
    margin-top: 10px; }
  .posterWrap .uploadPoster .btn-enable {
    background-color: #09b466;
    color: #ffffff;
    text-transform: capitalize;
    font-weight: 600; }
  .posterWrap .uploadPoster .ml {
    float: right; }
  .posterWrap .uploadPoster .alignButton {
    padding-top: 10px; }
    .posterWrap .uploadPoster .alignButton .custom-file-upload {
      width: 180px;
      margin-top: 5px;
      margin-right: 10px;
      outline: none; }
    .posterWrap .uploadPoster .alignButton:hover {
      background-color: transparent; }

.posterWrap .posterformInput {
  position: relative;
  left: 15px;
  margin-top: 20px; }
  .posterWrap .posterformInput textarea {
    min-height: 70px; }
  .posterWrap .posterformInput h3 {
    font-size: 2rem;
    font-weight: 500;
    color: #4a4a4a;
    width: 95%;
    margin: 0px; }
    .posterWrap .posterformInput h3 input {
      width: 95%;
      border: 1px solid #cecece; }
  .posterWrap .posterformInput .editDetail {
    margin-top: 10px;
    margin-bottom: 5px; }
    .posterWrap .posterformInput .editDetail__head {
      display: flex; }
    .posterWrap .posterformInput .editDetail h5 {
      font-weight: 600;
      color: #727272;
      font-size: 1rem;
      width: 100%; }
    .posterWrap .posterformInput .editDetail .MuiButton-label {
      color: #01a053; }
    .posterWrap .posterformInput .editDetail p {
      font-weight: 500;
      font-size: 1rem;
      color: #8e8e8e;
      text-align: left; }
      .posterWrap .posterformInput .editDetail p textarea {
        width: 100%;
        height: 50px;
        resize: none;
        margin-top: 5px;
        margin-right: 8px;
        border: 1px solid #cecece; }
        .posterWrap .posterformInput .editDetail p textarea:focus {
          outline: none; }
      .posterWrap .posterformInput .editDetail p span.icon {
        color: #01a053;
        font-size: 15px;
        margin-left: 6px; }
  .posterWrap .posterformInput p {
    /*      font-weight: 500;
      font-size: 1rem;
      color: #8e8e8e;
      text-align: left;
      text-align: left;
      // margin: 0px 15px;
      margin: 10px;
      margin-bottom: 5px;*/
    /*      span {
        font-weight: 600;
        color: #727272;
        font-size: 1rem;
      }*/ }
    .posterWrap .posterformInput p span.icon {
      color: #01a053;
      font-size: 20px; }
  .posterWrap .posterformInput .errorMsg1 {
    color: #ff0000;
    margin-top: 5px;
    font-weight: 700;
    font-size: 0.75rem;
    text-align: center; }
  .posterWrap .posterformInput .recomondationText {
    display: flex;
    border-bottom: 2px solid #cecece; }
    .posterWrap .posterformInput .recomondationText p {
      font-weight: 500;
      font-size: 1rem;
      color: #8e8e8e;
      text-align: left;
      text-align: left;
      /*margin: 5px 10px 5px 10px;*/ }
      .posterWrap .posterformInput .recomondationText p span {
        font-weight: 600;
        color: #57ac64;
        font-size: 1rem; }
  .posterWrap .posterformInput .capturePoint ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px; }
    .posterWrap .posterformInput .capturePoint ul li {
      display: block;
      color: #292a29;
      font-size: 1rem;
      flex: 0 1 auto;
      list-style-type: none; }
      .posterWrap .posterformInput .capturePoint ul li .selectText {
        display: flex;
        font-size: 0.875rem;
        font-weight: 500;
        color: #848484; }
        .posterWrap .posterformInput .capturePoint ul li .selectText span {
          color: #57ac64;
          font-size: 0.875rem;
          font-weight: 600; }
      .posterWrap .posterformInput .capturePoint ul li span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -5px 0px;
        font-size: 8px;
        font-weight: 500;
        color: #7b7d7b; }
        .posterWrap .posterformInput .capturePoint ul li span img {
          margin-right: -40px;
          padding: 0px 10px;
          cursor: pointer; }
  .posterWrap .posterformInput .checkBox {
    display: inline-block;
    border-bottom: 2px solid #cecece; }

/*New Select Poster CSS End*/
