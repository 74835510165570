.sms {
  /*padding: 40px 250px 40px 250px;*/
  display: flex;
  align-self: center;
  justify-content: center;
  &__create {
    /*display: flex;*/
    //padding: 10px;
    &--head {
      font-weight: 600;
      color: #727272;
      font-size: 1rem;
      margin-right: 20px;
      margin-top: 5px;
    }
    &--textarea {
      border-radius: 10px;
      border: 1px solid #09b466;
      background-color: #f9fef9;
      height: 100px;
      padding: 15px;
      width: 500px;
      display: block;
      resize: none;
      &:focus {
        outline: none;
      }
    }
    &--link {
      display: flex;
    }
    .inputHead {
      //margin-left: 45px;
      width: 535px;
    }
  }
  &__footer {
    margin-top: 10px;
  }
}
