.campaginDetials {
  text-align: right;
  padding: 1.5625rem;
  box-shadow: inset 0 -1px 0 0 #e4e8eb, inset -1px 0 0 0 #e4e8eb;
  background-color: #ffffff;
  i {
    color: #909191;
    cursor: pointer;
  }
  .deleteIcons {
    margin-right: 1.5625rem;
  }
}
.customPadding {
  padding: 1.5625rem;
  margin-top: 0.2rem;

  .commonLayout {
    width: 50px;
    width: 50px;
    display: inline-block;
    float: left;
    img {
      border-radius: 50%;
    }
  }
  .content {
    display: inline-block;
    margin-left: 0.875rem;
    margin-top: 0.325rem;
    border-bottom: 1px solid #979797;
    .campaginName {
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.54;
      letter-spacing: normal;
      color: #212223;
      width: 240px;

      .count {
        font-size: 0.8125rem;
        line-height: 1.54;
        color: #585b5e;
        opacity: 0.8;
        padding: 0.19rem;

        span {
          font-weight: 500;
          color: #212223;
        }
        .rightAlign {
          float: right;
        }
      }
    }
  }
}
.withBorder {
  padding: 1rem;
  margin: 20px;
  border: dotted;
  .initiateCampagin {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
    color: #2d2d2d;
  }
  .addMore {
    font-size: 0.8125rem;
    line-height: 1.54;
    letter-spacing: normal;
    color: #545556;
    opacity: 0.8;
    text-align: center;
    padding: 0.8125rem;
  }
  .btn {
    text-align: center;

    .btnAlign {
      /* margin-right: 2.5rem;
      font-size: 0.75rem;
      font-weight: 500;
      color: #2b304a;*/
      width: max-content;
    }
  }
}

/* ****** Campaign CSS Start *******  */

/* ****** Campaign CSS End  *******   */
.leftAside {
  background-color: #fff;
  height: 100%;
  .basePanel {
    /*    height: 480px;
    overflow-y: auto;*/
    width: 100%;
    overflow-x: hidden;
    .member_listHead {
      text-align: right;
      padding: 10px;
      box-shadow: inset 0 -1px 0 0 #e4e8eb, inset -1px 0 0 0 #e4e8eb;
      background-color: #ffffff;
      i {
        color: #909191;
        cursor: pointer;
      }
      .deleteIcons {
        margin-right: 1.5625rem;
      }
    }
    .member_list {
      max-height: 458px;
      height: 458px;
      overflow-y: auto;
      overflow-x: hidden;
      .list-body {
        margin-left: 15px;
        display: inline-block;
        width: 77%;
      }
      .sub-heading {
        margin-top: 3px;
        .pull-left {
          font-size: 12px;
          margin: 0px;
        }
        .pull-right {
          float: right;
          color: #909191;
          font-size: 12px;
        }
        strong {
          color: #909191;
          font-size: 13px;
        }
      }
      .left {
        width: 100%;
      }
      .active {
        background-color: #e4e8eb;
      }
      ul {
        padding: 0px 0px 0px 10px;
        width: 100%;
        li {
          padding: 6px;
          background-color: white;
          border-bottom: 1px solid #dddddd;
          padding: 6px;
          cursor: pointer;
        }
      }
      .list-heading {
        h1 {
          font-size: 14px;
          font-weight: bold;
          margin: 0px;
          span {
            float: right;
            border-radius: 12px;
            padding: 4px 10px 2px 10px;
            background-color: #09b466;
            color: white;
            font-size: 11px;
          }
        }
        h2 {
          color: #b1bbc6;
          font-size: 13px;
        }
      }
      .list-img img {
        height: 42px;
        width: 42px;
        border-radius: 50%;
      }
      .list-img .img-sms {
        width: 27px;
      }
    }
  }
}

/*.rightSide {
  padding: 0px 50px 0px 0px;
}*/
.campaignPoster img {
  width: 250px;
}
.campaginData {
  padding: 10px;
  h1 {
    font-size: 20px;
    font-weight: 500;
  }
  p {
    color: #8e8e8e;
    font-size: 12px;
  }
}
