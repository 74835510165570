.DateRangePicker_picker__portal.DateRangePicker_picker {
  z-index: 999;
  .DayPicker_portal__horizontal {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23), inset -1px 0 0 0 #d7e3f1;
    background-color: #ffffff;
    .DayPickerNavigation_button__default {
      border-radius: 5px;
      border: solid 0.8px #dee2ec;
      background-color: #ffffff;
    }
    .CalendarDay__default:focus {
      outline: none;
      border: none;
    }
    .CalendarDay__default {
      border-color: #d8e3f1;
      color: #9cb2cd;
    }
    .CalendarDay__selected_span {
      background: #09b466;
      border-color: #09b466;
      color: #fff;
    }
    .CalendarMonth_caption {
      color: #273142;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: #09b466;
      border-color: #09b466;
      color: #fff;
    }
    .CalendarDay__hovered_span {
      background: #eff6ff;
      border-color: #eff6ff;
    }
    .DateInput_input__small {
      font-size: 1rem;
    }
  }
}
.datePickerPanel {
  display: inline-block;
  h5 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #5a5a5a;
    margin-bottom: 6px;
  }
  .DateInput {
    position: static;
  }
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput_calendarIcon:focus {
    border: none;
    outline: none;
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: #09b466;
  }
  .DateInput__small {
    width: 70px;
  }
  .DateRangePickerInput_calendarIcon {
    margin: 0;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: solid 1px #09b466;
    background-color: #ffffff;
    height: 29px;
    padding-right: 22px;
  }

  .DateInput_input__small {
    padding: 5px 7px 4px;
    font-size: 0.9875rem;
  }
  .DateRangePickerInput_calendarIcon {
    padding: 4px 10px;
  }
}
