.recordTime {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  svg {
    color: #ff001f;
    font-size: 0.75rem;
    margin-right: 10px;
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
  }
  .timeSec {
    font-size: 3.125rem;
    font-weight: 400;
    letter-spacing: 1.9px;
    color: #678188;
  }
}
.progress-container {
  width: 50%;
  margin-top: 30px;
  overflow: initial;
  height: 5px;
  /* background-color: #f9fef9; */
  .progress,
  progress[value] {
    width: 100%;
    border: none;
    margin: 5px 0;
    height: 5px;
    display: block;
    appearance: none;
    -webkit-appearance: none;
    &::-webkit-progress-bar {
      background-color: blue primary 15%;
    }
    &::-webkit-progress-value {
      background-color: #09b466 primary;
    }
    //&:before {
    //  //content: "";
    //  //position: absolute;
    //  //right: 0px;
    //  //top: -5px;
    //  //width: 15px;
    //  //height: 15px;
    //  //background-color: #0d86ff;
    //  //border-radius: 100%;
    //  content: "";
    //  position: absolute;
    //  right: 0px;
    //  left: 95px;
    //  bottom: 0px;
    //  top: 47%;
    //  width: 15px;
    //  height: 15px;
    //  background-color: #0d86ff;
    //  border-radius: 100%;
    //}
  }
}

//   .recordProgress.progress {
//     margin-top: 70px;
//     overflow: initial;
//     height: 5px;
//     background-color: #e5eaf0; }
//     .recordProgress.progress .progress-bar {
//       background-color: #8eaac2;
//       position: relative; }
//       .recordProgress.progress .progress-bar:before {
//         content: "";
//         position: absolute;
//         right: 0px;
//         top: -5px;
//         width: 15px;
//         height: 15px;
//         background-color: #0d86ff;
//         border-radius: 100%; }
.duration {
  float: right;
  margin-right: 220px;
  font-size: 14px;
  color: #9b9b9b;
}
.recordButtons {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 32px 0px 15px 0px;
  li {
    display: inline-block;
    //margin-right: 100px;
    button {
      //display: inline-block;
      border: none;
      margin-left: 10px;
      border-radius: 4px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      svg {
        color: #d8dadc;
        font-size: 2.5rem;
      }
    }
    .startBtn {
      color: #09b466;
    }
    .stopBtn {
      color: #d0021b;
    }
    .disableBtn {
      background-color: lightgray;
    }
  }
}
.tagsTitle {
  padding: 10px;
  h4 {
    padding: 0px 0px 10px 20px;
  }
}
.ivrsTags {
  background-color: #e5e6e7;
  padding: 0px 5px 0px 0px;
  margin: 0px 0px 0px 10px;
  .inputHead {
    margin: 4px 0px 4px 5px;
  }
  ul {
    li {
      width: auto;
      height: 18px;
      padding: 5px;
      border-radius: 4px;
      display: inline-block;
      background-color: #95e5b3;
      margin: 5px 0px 5px 5px;
      color: #0b683e;
      cursor: pointer;
      i {
        font-size: 15px;
      }
    }
  }
}
.addGroup {
  padding: 0px 0px 0px 18px;
  h1 {
    font-size: 17px;
    color: #696969;
  }
  button {
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    background-color: #fafafa;
    margin-left: 20px;
    text-transform: capitalize;
    color: #696969;
    font-size: 15px;
    i {
      font-size: 18px;
    }
  }
}
