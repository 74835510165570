.survey {
  overflow: hidden;
  &__head {
    padding: 10px 10px 5px 10px;
    &__left {
      font-size: 11px;
      font-weight: 600;
    }
    &__list {
      font-size: 10px;
      text-align: center;
      ul {
        li {
          display: inline-block;
          /*width: 25%;*/
          margin-left: 12px;
          color: gray;
          margin-right: 0px;
        }
      }
    }
    &__right {
      font-size: 10px;
      a {
        color: #09b466;
        float: right;
      }
    }
  }
  &__datalist {
    padding: 0px 10px 0px 10px;
    ul {
      li {
        color: #9a9a9c;
        font-size: 9px;
        width: 25%;
        display: inline-block;
      }
    }
  }
  &__Palette {
    padding: 5px 0px 5px 0px;
    &--sky {
      background-color: #ceecde;
      padding: 2px;
    }
    &--grey {
      background-color: #f7f7f7;
      padding: 2px;
    }
    h4 {
      font-size: 10px;
      text-align: center;
      a {
        color: #09b466;
      }
      span {
        float: left;
        margin-left: 40px;
      }
    }
  }
  &__chart {
    border-bottom: solid 1px #d7d7d7;
    .inputHead {
      border-radius: 4px;
      border: solid 1px #09b466;
      background-color: #ffffff;
      margin-bottom: 18px;
    }
    .inputHead {
      width: 130px;
      margin-bottom: 10px;
    }
    .MuiInputBase-input {
      padding: 3px;
      font-size: 10px;
    }
    .MuiButton-label {
      font-size: 9px;
    }
    .coloredForms:after {
      top: 39%;
    }
    .inputHead {
      width: 126px;
      margin-left: -5px;
      margin-bottom: 10px;
    }
    .btn-enable {
      border-radius: 4px;
      background-color: #09b466;
      color: white;
      float: right;
      margin-right: 20px;
      height: 24px;
      text-transform: capitalize;
      font-weight: 600;
      &:hover {
        background-color: #09b466;
        color: white;
      }
    }
    &--table {
      margin-top: 0px;
      .MuiTableCell-root {
        padding: 9px 9px 0px 9px;
        font-size: 9px;
        color: #000000;
        opacity: 0.6;
        border-bottom: none;
      }
    }
    h1 {
      font-size: 10px;
      margin: 0px;
      font-weight: 600;
      padding: 5px 10px 0px 40px;
    }
    h2 {
      font-size: 8px;
      margin-bottom: 10px;
      padding-left: 30px;
      font-weight: 500;
    }
    .pie-chart {
      margin-top: -145px;
    }
  }
  &__report {
    padding: 0px 0px 0px 30px;
    .btn-enable {
      border-radius: 4px;
      background-color: #09b466;
      color: white;
      height: 24px;
      text-transform: capitalize;
      font-weight: 600;
      &:hover {
        background-color: #09b466;
        color: white;
      }
    }
    &--error {
      color: red;
      font-size: 9px;
      margin-bottom: 5px;
    }
    input {
      font-size: 10px;
      margin-left: 12px;
      &:focus {
        outline: 0;
      }
    }
    h1 {
      font-size: 10px;
      font-weight: 600;
      strong {
        font-size: 9px;
        font-weight: 600;
        color: #09b466;
        float: right;
        margin-right: 20px;
      }
      /*padding: 0px 20px 0px 30px;*/
    }
    h2 {
      font-size: 10px;
      font-weight: 500;
      padding-top: 5px;
    }
    h3 {
      font-size: 10px;
      font-weight: 600;
      margin-top: 5px;
    }
    .inputHead {
      width: 130px;
      margin-bottom: 10px;
      input {
        border: none;
        border-radius: 10px;
        padding: 5px;
        font-size: 10px;
        padding-left: 10px;
        &:focus {
          outline: none;
        }
      }
    }
    .MuiInputBase-inputSelect {
      font-size: 10px;
    }
    .MuiInputBase-input {
      padding: 3px;
    }
    .coloredForms:after {
      top: 39%;
    }
    .MuiButton-label {
      width: 100%;
      font-size: 9px;
    }
    .mb5 {
      margin-bottom: 5px;
    }
  }
  &__table {
    &__panel {
      thead {
        background-color: #fafafa;
        th {
          font-size: 9px;
          font-weight: 600;
          color: #000000;
          border-bottom: none;
        }
      }
      tbody {
        tr {
          background-color: #f2f3f6;
          h1 {
            font-size: 13px;
            color: #a7aeb6;
            padding: 10px;
          }
          td {
            border-bottom: 1px solid #fff;
            font-size: 9px;
          }
        }
        .icon {
          color: #09b466;
          font-size: 13px;
          cursor: pointer;
          &:focus,
          &:hover {
            outline: 0;
          }
        }
      }
      .MuiTableCell-root {
        padding: 4px;
        padding-left: 10px;
      }
      /*     margin-bottom: 20px;*/
      .MuiTablePagination-toolbar {
        height: 35px;
        min-height: 35px;
      }
    }
    /* border-bottom: solid 1px #d7d7d7;*/
  }
  .mb {
    margin-bottom: 50px;
  }
  .mt {
    margin-top: 10px;
  }
  .bb {
    border-bottom: solid 1px #d7d7d7;
  }
}
// Start with Survey Modal

.survey-modal {
  width: 330px;
  height: 407px;
  margin-top: -5px;
  border-radius: 6px;
  .tabPanelMain > header .MuiTabs-root {
    margin-left: 55px;
    background: #eeeff3;
    width: 330px;
    min-height: 34px;
  }
  .tabPanelMain > .MuiTypography-body1 {
    height: 330px;
    overflow: hidden;
  }
  .tabPanelMain > .MuiTypography-body1 {
    top: 34px;
    width: 100%;
    /*border: solid 1px #d7d7d7;*/
  }
  .tabPanelMain > header .MuiTabs-root {
    margin-left: 0;
  }
  .PrivateTabScrollButton-root-149 {
    width: auto;
    display: none;
  }
  .tabPanelMain
    > header
    .MuiTabs-root
    .MuiTabs-flexContainer
    .MuiTab-textColorPrimary.Mui-selected {
    width: 16.66%;
  }
  .PrivateTabScrollButton-root-246 {
    display: none;
  }
  .MuiTab-root {
    min-width: 30px;
    min-height: 34px;
  }
  .jss149 {
    display: none;
  }
 .MuiTabs-scrollButtons {
    display: none;
  }
  /*.MuiTabs-flexContainer{
    position: fixed;
  }*/
  .tabPanelMain
    > header
    .MuiTabs-root
    .MuiTabs-flexContainer
    .MuiTab-textColorPrimary.Mui-selected {
    background-color: #f0f0f0;
    border-bottom: 2px solid #09b466;
  }
  .tabPanelMain
    > header
    .MuiTabs-root
    .MuiTabs-flexContainer
    .MuiTab-textColorPrimary.Mui-selected
    .MuiTab-wrapper {
    color: black;
    font-size: 7px;
    text-transform: capitalize;
    font-weight: 600;
  }
  .tabPanelMain > header .MuiTabs-root .MuiTabs-flexContainer .MuiTab-wrapper {
    width: 44px;
    font-size: 7px;
    text-transform: capitalize;
  }
  .MuiTab-textColorPrimary.Mui-disabled {
    background-color: #a7aeb6;
  } /*
  .tabPanelMain > header .MuiTabs-root .MuiTabs-flexContainer .MuiTab-wrapper {
    color: #a7aeb6;
  }*/
  .tabPanelMain
    > header
    .MuiTabs-root
    .MuiTabs-flexContainer
    .MuiTab-textColorPrimary.Mui-disabled
    .MuiTab-wrapper {
    background-color: #a7aeb6;
    color: #a7aeb6;
  }
  .tabPanelMain > header .MuiTabs-root .MuiTabs-flexContainer .MuiTab-wrapper {
    padding: 0px;
  }
  .MuiTab-root {
    padding: 6px 5px;
  }
  .MuiButtonBase-root {
    background-color: white;
    border-right: solid 1px #d7d7d7;
    height: 20px;
    /*display: contents;*/
  }
  &__header {
    background-color: #09b466;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    h1 {
      margin: 0px;
      font-size: 11px;
      text-transform: uppercase;
      color: #fff;
      padding: 8px;
      text-align: center;
      i {
        font-size: 18px;
        float: right;
      }
    }
  }
  &__body {
    &__head {
      display: flex;
      border: solid 1px #d7d7d7;
      font-size: 10px;
      background-color: white;
      &--left {
        border-right: solid 1px #d7d7d7;
        padding-left: 16px;
      }
      &--right {
        padding-left: 16px;
      }
    }
    &__tabs {
      display: flex;
      text-align: center;
      &--01,
      &--02,
      &--03,
      &--04,
      &--05,
      &--06 {
        width: 16.66%;
        border: solid 1px #d7d7d7;
      }
    }
  }
}

//Survey Tab panel

.survey-tab {
  &__body {
    height: 290px;
    &__palette {
      background-color: #d9f4e8;
      h1 {
        margin: 0;
        font-size: 9px;
        font-weight: 600;
        color: #4a4a4a;
        padding: 5px 10px 4px 10px;
        span {
          float: right;
          strong {
            font-size: 7px;
            vertical-align: text-bottom;
          }
        }
      }
    }
    &__details {
      padding: 10px 10px 10px 15px;
      ul {
        li {
          font-size: 8px;
          margin-bottom: 2px;
          font-weight: 600;
          /*margin-bottom: 5px;*/
          span {
            float: right;
            margin-right: 18px;
            color: #9e9797;
          }
          strong {
            color: #09b466;
            font-size: 8px;
            font-weight: 600;
          }
        }
      }
    }
    &__img {
      display: flex;
      padding: 10px;
      img {
        width: 40px;
        height: 40px;
        display: flex;
      }
      p {
        text-align: center;
        font-size: 9px;
      }
    }
  }
  &__footer {
    display: flex;
    border-top: solid 1px #d2d4d5;
    padding: 10px 10px 0px 10px;
    &--btn {
      button {
        border-radius: 4px;
        background-color: #09b466;
        color: white;
        border: none;
        font-size: 10px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        font-weight: 600;
        padding: 4px 10px 4px 10px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.surveyMap {
  .mapboxgl-popup-content {
    background: transparent;
  }
}
