.farmerModalCustomise {
  width: 900vh;
  height: 60vh;
  .locationHeirachy {
    //width: 123px;
    //padding-left: 30px;
    .inputHead {
      text-align: left;
      padding-left: 35px;
    }
  }
}
